<template>
    <section class="py-5" style="padding-top: 0rem !important;">
        <div class="container" style="padding-left: 0;padding-right: 0">
            <!-- <iframe style="max-height: calc(100vh);width: <?php echo $jsonGame->data->width; ?>;height: <?php echo $jsonGame->data->height; ?>;" :src="game.url" width="100%" height="900" frameborder="0" scrolling="no"></iframe> -->
             <iframe :src="url_data"  frameborder="0" scrolling="no"></iframe>
        </div>
    </section>
</template>

<script>
// import { ref } from '@vue/reactivity';
// import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { watchEffect } from '@vue/runtime-core';
import { HTTPMTN } from '@/Api/http-mtn';
export default {
   name: 'AppGames',
   props: {
        gid: String
    },
    data() {
      return {
        url_data: null
      };
    },
    mounted() {
      if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
        this.$router.push("/");
      }
      this.url_data='http://196.1.255.181/GamesPage/index.php?id='+this.$route.params.gid;
    },
   setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();

      watchEffect(() => {
          if (cookie.isCookieAvailable("msisdn") && cookie.getCookie("status")) {
            HTTPMTN.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', cookie.getCookie("msisdn"), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        // toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    } else if (res.data.status == 0 && res.data.responseCode == 118) {
                        cookie.setCookie("msisdn", cookie.getCookie("msisdn"), { expire: 60 * 120, });
                        // toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                        // router.push({ name: "/"});
                        router.push({ path: "/" })
                    } else if (res.data.status == 0 && res.data.responseCode == 117) {
                        router.push({ path: "/" })
                        cookie.removeCookie('msisdn')
                        cookie.removeCookie('status')
                        cookie.removeCookie('MINTUES')
                    } else {
                        // toast.info("عفوا انت لست مشترك في خدمة تاق بريس الاخبارية" , { timeout: 2500 });
                        router.push({ path: "/" })
                    }
                })
          }
      });
      // const gamesDetails = ref([]);
      // try {
      //    HTTP.get(`GamesDetailsById.php?id=`+props.gid).then((res) => {
      //     gamesDetails.value = res.data.GamesDetails; 
      //   });
      // } catch (err) {
      //   console.log(err);
      // }
      const getFeed = (game) => {
        router.push({ name: "GamesDetails", params: { gid: game.gid } });
      };
      return {
        getFeed,
        // gamesDetails
      };
    }
}
</script>

<style>
iframe{
  width: 100%;
  height: 90vh;
  overflow:hidden;
}
@media(max-width:1024px){
  iframe{
    max-height: 90vh;
  }
}
@media(max-width:834px){
  iframe{
    max-height: 84vh;
  }
}
@media(max-width:820px){
  iframe{
    max-height: 84vh;
  }
}
@media(max-width:810px){
  iframe{
    max-height: 84vh;
  }
}
@media(max-width:786px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:768px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:428px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:414px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:412px){
  iframe{
    max-height: 82vh;
  }
}

@media(max-width:384px){
  iframe{
    max-height: 82vh;
  }
}
@media(max-width:375px){
  iframe{
    max-height: 82vh;
  }
}
@media(max-width:360px){
  iframe{
    max-height: 82vh;
  }
}
</style>